import Typewriter from "typewriter-effect";

function App() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-[#fdda00]">
      <img src="./logo.png" className="App-logo" alt="bee.zen" />

      <h1 className="flex font font-comfortaa text-3xl">
        <span className="mr-2">gerenciamento de </span>
        <Typewriter
          options={{
            strings: [
              "times",
              "escolas",
              "cursos",
              "equipes",
              "intranet",
              "faculdades",
              "ead corporativo",
            ],
            autoStart: true,
            loop: true,
          }}
        />
      </h1>
    </div>
  );
}

export default App;
